import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { PutObjectCommand, PutObjectCommandInput, S3Client } from '@aws-sdk/client-s3'
import { Upload } from "@aws-sdk/lib-storage"
import { COMMANDS, CONSTRAINTS, DEFAULT_PAGE_SIZE, ERROR_CODES, EVENTS, IBulkUploadStartCommand, IBulkUploadStartedEvent, ICancelBulkUploadCommand, ICreateFolderCommand, IFolderCreatedEvent, IGetRootQuery, IInitUploadStepFunctionCommand, IQuotaInfoCommand, IQuotaInfoRetrievedEvent, ISuggestTagsQuery, QUERIES, RESOURCE_KIND, UIOrigin } from 'prunus-common/dist'
import { ReplaySubject } from 'rxjs/internal/ReplaySubject'
import { Subject } from 'rxjs/internal/Subject'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { v4 as uuid } from 'uuid'
import { CleanupService } from '../../infrastructure/CleanupService'
import { UniversalCommandService } from '../../infrastructure/cqrs/universal-command.service'
import { ErrorHandlingService } from '../../infrastructure/ErrorHandlingService'
import { LoggingsService } from '../../infrastructure/LoggingsService'
import { FileSystemService } from '../../infrastructure/services/FileSystemService'
import { ServerSocketService } from '../../infrastructure/services/server-socket.service'
import { UniversalQueryService } from '../../infrastructure/services/universal-query.service'
import { SocketService } from '../../infrastructure/SocketService'
import { uniqueValues } from '../../infrastructure/UniqueValues'
import { formatMsg, MESSAGES } from '../../MESSAGES'
import { PARAMS } from '../../params'
import { IResource } from '../../resource/interface/IResource'
import { USER } from '../../user/USER'
import { AWSCredentialService } from '../../infrastructure/services/AWSCredentialService'
import { MY_FILES } from '../constants/MY_FILES'
import { TAG_REGEX } from '../constants/TAG_REGEX'
import { ExplorerDataSourceService } from '../explorer-view/explorer-data-source-service'
import { RecentTagsService } from '../../infrastructure/services/recent-tags.service'
import { IQItem } from './IQItem'
import { UploaderService } from './UploaderService'
import { NotificationService } from '../../infrastructure/services/notification-service'
import { requestWakeLock } from 'src/infrastructure/wakeLock'
import { SelectUploadFolderComponent } from '../select-upload-folder/select-upload-folder.component'
import { DIALOG_OPEN_OPTIONS } from 'src/infrastructure/DialogOptions'
import { BsModalService } from 'ngx-bootstrap/modal'
import { SESSION_ID } from 'src/SESSION_ID'

@Component({
  selector: 'app-upload-queue',
  templateUrl: './upload-queue.component.html',
  styleUrls: [ './upload-queue.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadQueueComponent implements OnInit, AfterViewInit, OnDestroy {
  suggestions$ = new Subject<string[]>()
  suggestionsLoading = false
  suggestionsInput$ = new Subject<string>()
  currentPage = 0
  pageSize = 5
  numberOfUploads: number
  cleanupService = new CleanupService(UploadQueueComponent.name)
  root: IResource
  createFolderCommands: ICreateFolderCommand[] = []
  onFolderStructureCreated: Function
  quota_exceeded = false
  s3Client: S3Client
  parallelUploads3: Upload
  bulkCmds: any[] = []
  currentSuggestions: string [] = []
  checkAll = true
  public MAX_SELECTED_ITEMS = CONSTRAINTS.MAX_NR_OF_USER_TAGS
  quota = 0
  quota$ = new Subject<number>()
  _bulkUid: string
  bulkStartedResolve: Function
  bulkStartedReject: Function
  bulkStartedEvent = new Promise<void>((resolve, reject) => {
    this.bulkStartedResolve = resolve
    this.bulkStartedReject = reject
  })
  uploadRootPath: string | undefined = undefined

  constructor (private notificationService: NotificationService,
               private socketService: SocketService,
               private explorerDataSourceService: ExplorerDataSourceService,
               private universalQueryService: UniversalQueryService,
               private universalCommandService: UniversalCommandService,
               private serverSocketService: ServerSocketService,
               private log: LoggingsService,
               private errorHandlingService: ErrorHandlingService,
               private awsCredentialService: AWSCredentialService,
               private fileSystemService: FileSystemService,
               private changeDetectorRef: ChangeDetectorRef,
               private recentTagsService: RecentTagsService,
               public uploaderService: UploaderService,
               private modalService: BsModalService
               ) {
                effect(() => {
                  if (this.uploadRootPath !== this.uploaderService.uploadRootPathSignal()?.newPath) {
                    this.calculateCurrentPageData()
                    this.changeDetectorRef.markForCheck()
                    this.uploadRootPath = this.uploaderService.uploadRootPathSignal()?.newPath
                  }
                })
  }

  ngOnInit(): void {
    const q: IGetRootQuery = {
      queryType: QUERIES.GetRootQuery
    }
    this.cleanupService.addSubscription(
      this.universalQueryService.query(q).subscribe(r => {
        this.root = r
      })
    )
    this.cleanupService.addSubscription(
      this.serverSocketService.subscribe2ServerEvents().subscribe(
        (evt: any) => this.onServerEvent(evt)
      )
    )
    this.cleanupService.addSubscription(
      this.errorHandlingService.errors$.subscribe(e => {
        if (e && (e.code === ERROR_CODES.QUOTA_EXCEEDED)) {
          this.quota_exceeded = true
          for(const iQItem of this.uploaderService.iQItems) {
            iQItem.isCancelled = true
          }
        } else {
          if (e.uri) {
            const errorItem = this.uploaderService.errorQItems.find(item => item.uri === e.uri)
            if (errorItem) {
              errorItem.isError = true
              this.uploaderService.addUpload(errorItem)
            }
          }
        }
      })
    )
    this.cleanupService.addSubscription(
      this.uploaderService.iQItems$.subscribe(t => {
        this.calculateNumberOfPages()
        this.calculateCurrentPageData()
        this.calculateCheckAll()
        this.changeDetectorRef.markForCheck()
      })
    )
  }

  get bulkUid(): string {
    return this._bulkUid
  }

  set bulkUid(value: string) {
    this._bulkUid = value
    this.uploaderService.bulkUid = value
  }

  fetchCurrentQuota() {
    const quotaInfoCmd : IQuotaInfoCommand = {
      uid: uuid(),
      commandType: COMMANDS.QuotaInfoCommand
    }
    this.universalCommandService.handle(quotaInfoCmd)
  }

  mapExtension2Mime(extension: string): string {
    switch (extension.toLowerCase()) {
      case 'jpeg':
      case 'jpg':
        return 'image/jpeg'
      case 'ai':
      case 'eps':
        return 'application/postscript'
      case 'svg':
        return 'image/svg+xml'
    }

    return `image/${extension}`
  }

  async s3Upload(qItem: IQItem, cmd: IInitUploadStepFunctionCommand, retry = 0): Promise<string> {
    if (qItem.isCancelled) { return 'cancelled' }
    qItem.isUploading = true
    const Bucket = PARAMS['S3_UPLOAD_BUCKET']
    this.s3Client = new S3Client({
      region: PARAMS['REGION'],
      credentials: await this.awsCredentialService.getCredentials()
    })
    let ContentType = qItem.file.type
    const uri = qItem.uri
    const parts = qItem.filename.split(".")
    let extension = parts[parts.length - 1]
    let Key = `${uri}`
    try {
      const Body: File = qItem.file
      if (!ContentType) {
        if (!extension) {
            extension = qItem.file.type.split("/")[1]
            switch (extension) {
              case "vnd.ms-powerpoint":
                extension = "ppt"
                break
              case "vnd.openxmlformats-officedocument.presentationml.presentation":
                extension = "pptx"
                break
              case "msword":
                extension = "docx"
                break
              case "vnd.openxmlformats-officedocument.wordprocessingml.document":
                extension = "docx"
                break
            }
        }
        ContentType = this.mapExtension2Mime(extension)
      }
      cmd.securityContext.currentUser.publications = []
      const params: PutObjectCommandInput = { Bucket, Key, Body, ContentType, Metadata: { "cmd": encodeURIComponent(JSON.stringify(cmd)) }  }
      if (extension === "pdf") {
        params["ContentDisposition"] = "inline"
      }
      this.parallelUploads3 = new Upload({
        client: this.s3Client,
        params,
        queueSize: 4, // optional concurrency configuration
        partSize: 1024 * 1024 * 5, // optional size of each part, in bytes, at least 5MB
        leavePartsOnError: false, // optional manually handle dropped parts
      })
      this.parallelUploads3.on('httpUploadProgress', (progress) => {
        if (progress.loaded && progress.total) {
          qItem.progress = (progress.loaded / progress.total) * 100
          this.changeDetectorRef.markForCheck()
        }
      })

      const result = await this.parallelUploads3.done()
      qItem.isSuccess = true

      return Key
    } catch (e) {
      if (e['name'] === 'AbortError') {
        qItem.isCancelled = true
        return 'AbortError'
      }
      if ((retry < 4) && (!qItem.isCancelled)) {
        setTimeout(async () => {
          try {
            await this.s3Upload(qItem, cmd, ++retry)
            return Key
          } catch(e) {
            if ((retry < 4) && (!qItem.isCancelled)) {
              return Promise.reject(new Error(`retry nr: ${retry} ${JSON.stringify(qItem)} upload failed cause : ${JSON.stringify(e)}`))
            } else {
              if(!qItem.isCancelled) {
                this.log.error(`retry nr: ${retry} ${JSON.stringify(qItem)} upload finally failed after 4 retries cause : ${JSON.stringify(e)}`)
              }
            }
        }
        }, 1000)

        return undefined
      }
      qItem.isError = true
      this.errorHandlingService.handleError(`fout tijdens uploaden ${qItem.filename} fout : ${JSON.stringify(e)}, ContentType: ${ContentType}`)

      return Promise.reject(e)
    }
  }

  ngOnDestroy(): void {
    this.cleanupService.cleanupSubscriptions()
    this.currentSuggestions = []
  }

  currentPageData : IQItem[] = []
  numberOfPages: number

  calculateCurrentPageData() {
    const start = this.currentPage * this.pageSize

    this.currentPageData = [ ...this.uploaderService.iQItems.slice(start, start + this.pageSize) ]
  }

  calculateNumberOfPages() {
    this.numberOfPages = Math.ceil(this.uploaderService.iQItems.length / this.pageSize)
    this.changeDetectorRef.markForCheck()
  }

  get pageNumbers (): number[] {
    return Array(this.numberOfPages).fill(0).map((x, i) => i)
  }

  ngAfterViewInit () {
    this.cleanupService.addSubscription(
      this.suggestionsInput$.pipe(
        distinctUntilChanged(),
        debounceTime(500)
      ).subscribe((term) => {
        const q: ISuggestTagsQuery = {
          queryType: QUERIES.SuggestTagsQuery,
          from: 0,
          size: DEFAULT_PAGE_SIZE,
          term
        }
        const subscription = this.universalQueryService.query(q).subscribe((results: string[]) => {
          const recents = this.recentTagsService.findRecent(term)
          this.suggestions$.next(uniqueValues([...recents, ...results]).sort())
          for(const r of results) {
            if (!this.currentSuggestions.includes(r)) {
              this.currentSuggestions.push(r)
            }
          }
          subscription.unsubscribe()
        })
        this.changeDetectorRef.markForCheck()
      })
    )
  }

  /**
   * Called whenever an item is removed from te queue
   * @param iQItem
   **/
  onItemRemove (iQItem: IQItem): void {
    const index = this.findIndexInQ(iQItem)
    if (index !== -1) {
      this.uploaderService.removeUpload(index)
      this.calculateCheckAll()
      this.calculateCurrentPageData()
      this.calculateNumberOfPages()
      this.changeDetectorRef.markForCheck()
    } else {
      this.log.warn(`Could not locate iQItem ${iQItem.filename} in queue to remove`)
    }
  }

  /**
   * Called when the remove button is clicked, the currently checked items should be remove d from the queue
   */
  clearCheckedItems (): void {
    this.uploaderService.iQItems.filter(item => item.checked)
      .forEach((iQItem: IQItem, index: number) => this.onItemRemove(iQItem))

    const highestPageIndex = Math.floor(this.uploaderService.iQItems.length / this.pageSize)

    if (this.currentPage > highestPageIndex) {
      this.currentPage = highestPageIndex
      this.changeDetectorRef.markForCheck()
    }
  }

  /**
   * Called when the upload button is clicked
   */
  async uploadCheckedItems () {
    const quotaOk = await this.checkQuota()
    if (quotaOk) {
      this.uploaderService.isUploading = this.uploaderService.isBatchUploading = true
      const uris: string[] = this.uploaderService.newFolders.map(nf => nf.uri)
      const infos: any[] = this.uploaderService.newFolders.map(nf =>{
        return { fullPath: this.fileSystemService.path + "/" + nf.name, kind: RESOURCE_KIND.FOLDER }
      })

      this.uploaderService.errorQItems = []
      this.uploaderService.copyItems()
      this.bulkUid = this.uploaderService.iQItems.length > 1 ? uuid() : ""

      for(const item of this.uploaderService.iQItems) {
        item.isUploading = true
        item.bulkUid = this.bulkUid
        if (item.isCancelled) item.isCancelled = false
        uris.push(item.uri)
        infos.push({fullPath: item.fullPath, kind: RESOURCE_KIND.FILE})
        this.uploaderService.errorQItems.push(item)
      }
      this.changeDetectorRef.markForCheck()
      const bulkStartSubject = new ReplaySubject<void>()
      this.onFolderStructureCreated = async () => {
        this.cleanupService.addSubscription(
        bulkStartSubject.subscribe(async () => {
            this.log.debug(`start actual upload`)
            let counter = 1
            for(const copyItem of this.uploaderService.copyIQItems) {
              if (this.quota_exceeded) {
                break
              }
              if (copyItem.checked && !copyItem.isCancelled) {
                try {
                  await this.uploadItem(copyItem)
                  this.log.debug(`uploaded item ${counter++} ${copyItem.filename} / ${this.uploaderService.copyIQItems.length}`)
                } catch(e) {
                  this.log.error(`uploadCheckedItems caught: ${e}`)
                }
              }
            }
            this.log.debug(`after loop over copyQ`)
          }
        )
        )
      }
      if(this.uploaderService.isBatchUploading && uris.length > 1) {
        this.uploaderService.isBulkUploadProcessingOnServerSignal.set(true)
        const bulkStartCmd: IBulkUploadStartCommand = {
          bulkUid: this.bulkUid,
          uid: uuid(),
          commandType: COMMANDS.BulkUploadStartCommand,
          uris,
          created_at: new Date(),
          path: this.fileSystemService.path
        }
        bulkStartCmd["infos"] = infos
        await this.universalCommandService.sendByPost(bulkStartCmd, bulkStartSubject)
        requestWakeLock()
      } else {
        this.bulkUid = undefined
        bulkStartSubject.next()
        await this.ensureFolderStructure()
      }
    }
  }

  async checkQuota(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
        if (!USER.isTeacher) {
          resolve(true)

          return
        }
        this.fetchCurrentQuota()
        this.quota$.subscribe(async(quota) => {
          const newQuota =  quota + (this.uploaderService.totalQSize() * 1024)
          if (newQuota > CONSTRAINTS.MAX_QUOTA_KB) {
            this.notificationService.error(`
            Onvoldoende opslagruimte. ${((CONSTRAINTS.MAX_QUOTA_KB - quota) / 1024).toFixed(2)}MB resterend.
<br />- verwijder bestanden
<br />- gebruik kleinere bestanden`, "", {enableHtml: true})

            resolve(false)

            return
          }
          resolve(true)

          return
        })

      }
    )
  }

  async ensureFolderStructure() {
    this.createFolderCommands = []
    if (this.uploaderService.newFolders.length) {
      this.notificationService.info(MESSAGES.START_CREATE_FOLDERS_ON_UPLOAD)
    }
    // start by creating the new folders
    for(const newFolder of this.uploaderService.newFolders) {
      /*const activeFolder = this.uploaderService.iQItems.find(item => item.fullPath.toLowerCase().startsWith(newFolder.name.toLowerCase()))
      if (!activeFolder) {
        continue
      }*/
      const parts = newFolder.name.split('/')
      const name = parts[parts.length - 1].toLowerCase()
      const cmd: ICreateFolderCommand = {
        uid: newFolder.uri,
        commandType: COMMANDS.CreateFolderCommand,
        path: parts.slice(0, parts.length - 1).join('/').toLowerCase(),
        originatedFrom: UIOrigin.PrunusUI,
        name,
        bulkUid: this.bulkUid
      }
      this.createFolderCommands.push(cmd)
    }

    await this.handleCreateFolderCmds()
    this.uploaderService.newFolders = []
  }

  async handleCreateFolderCmds() {
     if (!this.createFolderCommands.length) {
       await this.onFolderStructureCreated()

       return
     }
     this.universalCommandService.handle(this.createFolderCommands[0])
  }

  /**
   * Subscription to events of processed command by the server
   */
  async onServerEvent(evt) {
    if (evt.eventType === EVENTS.QuotaInfoRetrievedEvent) {
      const quotaInfoRetrievedEvent = evt as IQuotaInfoRetrievedEvent
      this.quota = evt.currentQuota
      this.quota$.next(this.quota)

      return
    }

    if (evt.eventType === EVENTS.BulkUploadStartedEvent) {
      const iBulkUploadStartedEvent =  evt as IBulkUploadStartedEvent
      if (iBulkUploadStartedEvent.bulkUid === this.bulkUid) {
        await this.ensureFolderStructure()
      }
    }

    if (this.createFolderCommands.length === 0) {

      return
    }

    if (evt.eventType === EVENTS.FolderCreatedEvent) {
      let idx: number = 0
      try {
        const iFolderCreatedEvent = evt as IFolderCreatedEvent
        idx = this.createFolderCommands.findIndex(
          c => (
            (c.uid === iFolderCreatedEvent.resource.uri) ||
            (c.name === iFolderCreatedEvent.resource.name && c.path === iFolderCreatedEvent.resource.path)
          )
        ) // in case the folder already existed, the old uri is returned by server but path and name should be equal
        if (idx === -1) {
          return
        }

        if (this.fileSystemService.path !== iFolderCreatedEvent.resource.path) {
          this.notificationService.success(
            formatMsg(MESSAGES.NEW_FOLDER_CREATED, {name: iFolderCreatedEvent.name})
          )
        }
      } finally {
        if (this.createFolderCommands[idx]) {
          this.createFolderCommands.splice(idx, 1)
          await this.handleCreateFolderCmds()
        } else {
          await this.onFolderStructureCreated()
        }
      }
    }
  }

  /**
   * Called when the cancel button is clicked, the currently checked items should be cancelled
   */
  async cancelCheckedItems () {
    if (this.uploaderService.isBatchUploading) {
      const cancelBulkSubject = new Subject()
      const cancelBulkUplCmd: ICancelBulkUploadCommand = {
        bulkUid: this.bulkUid,
        uid: uuid(),
        commandType: COMMANDS.CancelBulkUploadCommand,
      }
      await this.universalCommandService.sendByPost(cancelBulkUplCmd, cancelBulkSubject)
    }
    const abortPromise = this.parallelUploads3 ? this.parallelUploads3.abort() : undefined
    const checkedItems = this.uploaderService.iQItems.filter(item => item.checked)
    this.uploaderService.isBatchUploading = false
    for(const item2Cancel of checkedItems) {
      item2Cancel.isCancelled = true
      item2Cancel.isUploading = false
    }
    this.uploaderService.isBatchUploading = false
    this.uploaderService.isUploading = false
    this.changeDetectorRef.markForCheck()
    await abortPromise
  }

  async onCancelItem(item: IQItem) {
    item.isCancelled = true
    if (this.parallelUploads3) {
      await this.parallelUploads3.abort()
    }
    item.isUploading = false
    this.changeDetectorRef.markForCheck()
  }

  async onItemUpload (index: number, item: any) {
    const quotaOk = await this.checkQuota()
    if (quotaOk) {
      this.onFolderStructureCreated = async () => {
        const qItem = this.uploaderService.iQItems[index]
        if (qItem.fullPath.length > CONSTRAINTS.MAX_PATH_LENGTH) {
          this.notificationService.error(`Gelieve uw folder structuur minder diep te maken. De nieuwe upload: ${qItem.filename} zou de maximale pad lengte (${CONSTRAINTS.MAX_PATH_LENGTH}) overschrijden.`)

          return
        }
        qItem.isCancelled = false
        await this.uploadItem(qItem)
        if (!this.uploaderService.isBatchUploading) {
          this.uploaderService.isUploading = false
        }
      }
      await this.ensureFolderStructure()
    }
  }

  async uploadItem(qItem: IQItem): Promise<void> {
    try {
      this.uploaderService.isUploading = qItem.isUploading = true
      this.changeDetectorRef.markForCheck()
      await this.onBeforeUpload(qItem)
      let s3Key = qItem.uri
      const parts = qItem.filename.split(".")
      const extension = parts[parts.length - 1]
      const cmd: IInitUploadStepFunctionCommand = {
        tags: qItem.tags,
        s3Info: {
          Bucket: PARAMS['S3_UPLOAD_BUCKET'],
          Key: s3Key
        },
        name: qItem.filename,
        fileSize: qItem.file.size / 1024,
        socketId: this.socketService.socket_id,
        path: qItem.path,
        owner: USER.email,
        securityContext: {
          currentUser: USER,
        },
        uid: s3Key,
        uri: s3Key.replace(".master", ""),
        commandType: COMMANDS.InitUploadStepFunctionCommand,
        parentFolderUri: this.explorerDataSourceService['parentResourceURI'],
        isUpdate: false,
        currentState: {},
        extension,
        BulkUploadUid: qItem.bulkUid,
        session_id: SESSION_ID
      }
      s3Key = await this.s3Upload(qItem, cmd)
      this.onItemUploadComplete(qItem)
    } catch(e) {
      this.log.error('error while uploading', e)
      this.notificationService.error(e.toString())
      return Promise.reject(e)
    }
  }

  findIndexInQ(iQItem: IQItem) {
    return this.uploaderService.iQItems.findIndex(item => {
      return item.filename === iQItem.filename &&
            // item.file.size ===  iQItem.file.size &&
            // item.file.lastModified === iQItem.file.lastModified &&
             item.fullPath === iQItem.fullPath
    })
  }

  /***
   * Called whenever new or existing tags are associated with a line of the upload queue
   * @param $event
   * @param {number} index
   */
  newTagsSelectionChanged ($event: any, index: number): void {
    this.uploaderService.iQItems[ index ].tags = $event
  }

  onOpen() {
    if (!this.suggestionsInput$) {
      return
    }
    this.suggestionsInput$.next('')
  }

  /***
   * check all checkboxes or uncheck (toggle) depending on current state
   * @param $event
   */
  toggleSelectAll ($event) {
    this.uploaderService.iQItems.forEach(item => item.checked = $event.srcElement.checked)
    this.checkAll = $event.srcElement.checked
  }

  checkedChange($event) {
    this.calculateCheckAll()
    this.changeDetectorRef.markForCheck()
  }

  calculateCheckAll(): void {
    this.checkAll = this.uploaderService.iQItems.filter(iq => iq.checked).length === this.uploaderService.iQItems.length
  }

  nextPage (): void {
    if (this.currentPage < this.numberOfPages - 1) {
      this.currentPage ++
      this.changeDetectorRef.markForCheck()
    }
  }

  previousPage (): void {
    if (this.currentPage > 0) {
      this.currentPage --
      this.changeDetectorRef.markForCheck()
    }
  }

  setPage (index: number): void {
    this.currentPage = index
    this.calculateCurrentPageData()
    this.changeDetectorRef.markForCheck()
  }

  /***
   * Callback for each item of the upload queue that is succesfully uploaded
   * @param {string} response
   * @param {number} status
   * @param {ParsedResponseHeaders} headers
   */
  private onItemUploadComplete (
   iQItem: IQItem
  ): void {
    const lastDotIndex = iQItem.filename.lastIndexOf('.')
    const name = iQItem.filename.substring(0, lastDotIndex)
    const extension = iQItem.filename.substring(lastDotIndex + 1)
    const resource: IResource = {
      uri: iQItem.uri,
      isNew: true,
      name: `${name}.${extension}`,
      thumbnail: iQItem.thumb,
      path: iQItem.path,
      extension,
      version: 0,
      last_modified_at: undefined,
      isOwnedByTeacher: false,
      processedByServer$: new Subject<any>(),
      isNotYetProcessedByServer: true,
      kind: RESOURCE_KIND.FILE,
      fileSize: iQItem.file.size / 1024,
      tags: iQItem.tags
    }

    this.explorerDataSourceService.newTmpNotYetProcessedResource$.next(resource)
    this.onItemRemove(iQItem)

    if (this.uploaderService.getActive2UploadItems().length === 0) {
      this.onAllUploadsComplete()
    }
    if (!this.uploaderService.isBatchUploading) {
      iQItem.isUploading = false
      this.uploaderService.isUploading = false
    }

    const msg = `Bestand(en) succesvol verzonden naar server: '${iQItem.filename}'.`
    this.notificationService.success(msg)
    this.log.debug(msg)
    this.calculateCheckAll()
    this.changeDetectorRef.markForCheck()
  }

  /***
   * Interceptor that hooks in before each upload, to attach the associated tags in the body of the post
   */
  private onBeforeUpload (qItem: IQItem): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.numberOfUploads = this.uploaderService.iQItems.length
      resolve()
    })
  }

  /***
   * Fires after all uploads are finished
   */
  private onAllUploadsComplete (): void {
    this.createFolderCommands = []
    this.log.debug(`onAllUploadsComplete`)
    this.uploaderService.isBatchUploading = false
    this.uploaderService.isUploading = false
    if (PARAMS['SEND_CMD_BATCHES'] && this.bulkCmds.length) {
      this.universalCommandService.bulkHandle(this.bulkCmds)
      this.bulkCmds = []
    }
    this.changeDetectorRef.markForCheck()
  }

  get queueSize(): string {
    return this.uploaderService.totalQSize().toFixed(2)
  }

  replaceMyFiles(path: string): string {
    const parts = path.split('/').filter(e => e)
    if (parts[1] === USER.email) {
      parts[1] = MY_FILES
    }

    return parts.join('/')
  }

  tagAdded($event, index: number) {
    if ($event && $event.length > CONSTRAINTS.MAX_RESOURCE_NAME_LENGTH) {
      const index2Del = this.uploaderService.iQItems[index].tags.findIndex(t => t === $event)
      if (index2Del !== -1) {
        this.uploaderService.iQItems[index].tags.splice(index2Del, 1)
      }
      this.notificationService.error(`Tag maximum karakter lengte : ${CONSTRAINTS.MAX_RESOURCE_NAME_LENGTH}, huidige lengte ${$event.length}.`)
      this.changeDetectorRef.markForCheck()

      return
    }
    if(!TAG_REGEX.test($event)) {
      const index2Del = this.uploaderService.iQItems[index].tags.findIndex(t => t === $event)
      if (index2Del !== -1) {
        this.uploaderService.iQItems[index].tags.splice(index2Del, 1)
      }
      this.uploaderService.iQItems[index].tags = [ ... this.uploaderService.iQItems[index].tags.sort()]
      this.notificationService.error(MESSAGES.TAGS_ALFA_NUM)

      return
    }
    if (!this.currentSuggestions.includes($event)) {
      this.recentTagsService.add($event)
    }
  }

  selectUploadFolder() {
    this.modalService.show(SelectUploadFolderComponent, DIALOG_OPEN_OPTIONS)
  }

}
